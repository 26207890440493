import em from "./em.png"
import pc from "./pc.png"
import dis from "./whatsapp.png"
import ig from "./ig.png"
import email from "./email.png"
import { Link } from "react-router-dom"
import tw from "./twitter.png"

function Footer() {
        return (
                <div className="Footer">
                        <div className="main-footer-box ">
                                <div className="footer-box">
                                        <div className="company-footer">
                                                <p className="logoText" >AASHIYANA RECOVERY HOME</p>
                                                <p className="footer-about" >Aashiyana is a non-profit organization.</p>
                                                <p className="footer-reach">Reach out to us</p>
                                                <p className="footer-about" >Get your questions answered about ARH</p>
                                                <div className="footer-icons">

                                                        <p className="footer-email" ><a href="mailto:strayfarm9@gmail.com" >demo@gmail.com</a></p>


                                                </div>
                                                <div className="footer-icons">



                                                        <p className="footer-email" ><a href="mailto:strayfarm9@gmail.com" >+91 1234567890</a></p>
                                                </div>

                                        </div>
                                        <div className="second-footer">
                                                <div className="">
                                                        <p className="footer-head-sub">Organization</p>
                                                        <div className="footer-menu">
                                                                <Link to="/about">
                                                                        <p className="footer-menuItem">About</p>
                                                                </Link>
                                                                <Link to="/adopt">
                                                                        <p className="footer-menuItem">Adopt</p>
                                                                </Link>
                                                                <Link to="/donate">
                                                                        <p className="footer-menuItem">Donate</p>
                                                                </Link>
                                                                <Link to="/resident-animals">
                                                                        <p className="footer-menuItem">Resident Animals</p>
                                                                </Link>
                                                                <Link to="/contact">
                                                                        <p className="footer-menuItem">Contact</p>
                                                                </Link>





                                                        </div>
                                                </div>

                                                <div className="">
                                                        <p className="footer-head-sub">Support</p>
                                                        <div className="footer-menu">
                                                                <Link to="/privacy-policy">
                                                                        <p className="footer-menuItem">Privacy Policy</p>
                                                                </Link>
                                                                <Link to="/terms">
                                                                        <p className="footer-menuItem">Terms & Conditions</p>
                                                                </Link>
                                                                <Link to="/refund-policy">
                                                                        <p className="footer-menuItem">Refund Policy</p>
                                                                </Link>




                                                        </div>
                                                </div>

                                        </div>
                                </div>
                                <div className="lower-footer">
                                        <div>

                                        </div>
                                        <div className="lower-footer-icons">

                                                <a href="mailto:strayfarm9@gmail.com" target="blank">
                                                        <img className="lower-footer-icon" src={email} />
                                                </a>
                                                <a href="https://instagram.com/strayfarm" target="blank">
                                                        <img className="lower-footer-icon" src={ig} />
                                                </a>



                                        </div>
                                </div>
                        </div>
                </div>
        )
}

export default Footer;