import "../../global.scss"
import rescue from "./rescue.jpg"
import rightImg from "./ri.jpg"
import firebase from 'firebase/compat/app';
import recovery from "./recovery.jpg"
import release from "./release.jpg"
import shelter from "./shelter.jpg"
import { useEffect, useRef, useState } from "react"
import amb from "./amb.jpg"
import adopt from "./adoption.jpg"
import Nav from "../nav/nav"
import Iframe from "react-iframe"
import { Link } from "react-router-dom"
import animals from "../../animals.json"
import { firestore } from "../../firebase"
import cross from "./close.png"

import success from "./success.png"
import warning from "./warning.png"
import da from "./da.png"

function Home() {

    const [review, setReview] = useState("")
    const [rname, setRname] = useState("")
    const [rphone, setRphone] = useState("")
    // const



    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        amount: 0
    });
    const [currentAnimal, setCurrentAnimal] = useState(0)

    const [currentAnimalName, setCurrentAnimalName] = useState(0)

    const [currentAnimalSponsor, setCurrentAnimalSponsor] = useState(0)

    const [currentAnimalNameSponsor, setCurrentAnimalNameSponsor] = useState(0)

    const [payAmount, setPayAmount] = useState(0)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_test_nUKVQmPiWBQbzE', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {
                    const paymentId = response.razorpay_payment_id;
                    document.getElementById("adoptFormSponsor").style.display = "none"
                    document.getElementById("thanksBoxSponsor").style.display = "flex"
                    document.getElementById("fillTextSponsor").style.display = "none"

                    const docId = firestore.collection("sponsors").doc().id
                    firestore.collection("sponsors").doc(docId).set({
                        id: docId,
                        animalId: currentAnimal,
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        timePeriod: catValue,
                        animalName: currentAnimalName,
                        amount: payAmount,
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        paymentId: paymentId
                    }, { merge: true })


                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };


    const handleReview = () => {
        const docId = firestore.collection("reviews").doc().id
        firestore.collection("reviews").doc(docId).set({
            id: docId,

            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            animalName: currentAnimalName,
            date: firebase.firestore.FieldValue.serverTimestamp(),
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        const docId = firestore.collection("adoptions").doc().id
        firestore.collection("adoptions").doc(docId).set({
            id: docId,
            animalId: currentAnimal,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            animalName: currentAnimalName,
            date: firebase.firestore.FieldValue.serverTimestamp(),
        })
        document.getElementById("adoptForm").style.display = "none"
        document.getElementById("thanksBox").style.display = "flex"
        document.getElementById("fillText").style.display = "none"
    }

    const closeAdopt = () => {
        document.getElementById("adoptForm").style.display = "flex"
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("overlayAdoptDiv").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimal(0)
        setCurrentAnimalName("")
    }


    const openAdopt = (aid, aname) => {
        setCurrentAnimal(aid)
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setCurrentAnimalName(aname)
        document.getElementById("overlayAdoptDiv").style.display = "flex"
        document.getElementById("adoptForm").style.display = "flex"


    }

    const [whichMode, setWhichMode] = useState("")

    const openSponsor = (aid, aname, mode) => {
        setCatValue("Select period")
        setWhichMode(mode)
        setCurrentAnimal(aid)
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        document.getElementById("errorBoxSponsor").style.display = "none"
        setCurrentAnimalName(aname)
        document.getElementById("overlaySponsorDiv").style.display = "flex"
        document.getElementById("adoptFormSponsor").style.display = "flex"
        setPayAmount(0)
    }
    const [catValue, setCatValue] = useState("Select period")
    const closeSponsor = () => {
        setCatValue("Select period")
        document.getElementById("adoptFormSponsor").style.display = "flex"
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("errorBoxSponsor").style.display = "none"
        document.getElementById("overlaySponsorDiv").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimalSponsor(0)
        setCurrentAnimalNameSponsor("")
        setPayAmount(0)
    }





    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const openCats = () => {
        if (isOpen) {
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);












    return (

        <>
            <div id="overlayAdoptDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillText" className="head-div">
                        <p>Fill the form to adopt {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeAdopt} src={cross} />

                    </div>
                    <form id="adoptForm" className="payment-sec" onSubmit={handleSubmit}  >
                        <input className="pay-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="pay-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="pay-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                        <input className="pay-inp af" type="text" name="address" placeholder="Full address" value={formData.address} onChange={handleChange} required />
                        <input type="submit" className="pay-button" value="Submit" />
                    </form>
                    <div id="thanksBox" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Your details have been submitted successfully.
                        </p>
                        <p className="teamText">
                            Our team will reach you shortly.
                        </p>
                        <button onClick={closeAdopt} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>

            <div id="overlaySponsorDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillTextSponsor" className="head-div">
                        <p>Fill the form to sponsor {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeSponsor} src={cross} />

                    </div>
                    <form id="adoptFormSponsor" className="payment-sec" onSubmit={(e) => {
                        handlePayment(e)
                    }}  >
                        <div class="dropdown" ref={dropdownRef}>
                            <div onClick={openCats} className="drpbox">
                                <p class="dropbtn">{catValue}</p>
                                <img className={isOpen ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpen && (
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setCatValue("1 month"); setIsOpen(false); setPayAmount(1); setFormData({ ...formData, amount: 1 }); }}>1 month</p>
                                    <p href="#" onClick={() => { setCatValue("3 months"); setIsOpen(false); setPayAmount(300); setFormData({ ...formData, amount: 300 }); }}>3 months</p>
                                    <p href="#" onClick={() => { setCatValue("6 months"); setIsOpen(false); setPayAmount(600); setFormData({ ...formData, amount: 600 }); }}>6 months</p>
                                    <p href="#" onClick={() => { setCatValue("12 months"); setIsOpen(false); setPayAmount(1200); setFormData({ ...formData, amount: 1200 }); }}>12 months</p>
                                </div>
                            )}
                        </div>
                        <input className="pay-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="pay-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="pay-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />

                        <input type="submit" className={catValue == "Select period" ? "pay-button disabled" : "pay-button"} value={"Pay Rs." + payAmount} />
                    </form>
                    <div id="thanksBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Thanks you for sponsoring {currentAnimalName}
                        </p>
                        <p className="teamText">
                            We have sent you the receipt via email
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>

                    <div id="errorBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={warning} />
                        <p className="thanksText errorText">
                            Payment failed
                        </p>
                        <p className="teamText errorText">
                            Please try again
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>


            <div className="Home">
                <div className="home-box">
                    <div className="main-box">
                        <Nav />
                        <div className="main-box-sub">
                            <div className="left-main-box">
                                <p className="tagline">
                                    Building a happy place for animals
                                </p>
                                <p className="left-main-box-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                </p>

                                <div className="home-buttons">

                                    <a className="hbd" href="tel:+91 9015773750">
                                        <button className="home-button helpline">
                                            Helpline
                                        </button>
                                    </a>

                                    <Link to='/donate' className="hbd" >
                                        <button className="home-button donate">
                                            Make a donation
                                        </button>

                                    </Link>
                                </div>
                            </div>
                            <div className="right-main-box about-right">
                                <Iframe url="https://www.youtube.com/embed/7PIji8OubXU" className="yt-video"></Iframe>
                            </div>

                        </div>

                    </div>
                    <div className="home-lower-box">
                        <div className="whatWeDoBoxMain">
                            <center>
                                <p className="heading wwd">What we do ?</p>
                            </center>

                            <div className="whatWeDoBoxes">
                                <div className="whatWeDoBox">
                                    <div className="whatWeDoLeft">

                                        <p className="subHead">Rescue</p>
                                        <p className="subText">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                        <div className="home-sub-button-box release-div">
                                            <Link className="hbd" to='/donate'>
                                                <button className="donate-button">
                                                    Help us to rescue more
                                                </button>
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="whatWeDoRight">
                                        <img className="rescue" src={rescue} />
                                    </div>
                                </div>
                                <div className="whatWeDoBox">
                                    <div className="whatWeDoRight">
                                        <img className="rescue" src={recovery} />
                                    </div>
                                    <div className="whatWeDoLeft">

                                        <p className="subHead recovery">Recovery</p>
                                        <p className="subText recovery">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>

                                        <div className="home-sub-button-box">
                                            <Link className="hbd" to='/donate'>
                                                <button className="donate-button">
                                                    Help animals to recover fast
                                                </button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                                <div className="whatWeDoBox">
                                    <div className="whatWeDoLeft">

                                        <p className="subHead ">Release</p>
                                        <p className="subText">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                        <div className="home-sub-button-box release-div">
                                            <Link className="hbd" to='/adopt'>
                                                <button className="donate-button">
                                                    Give them a happy home
                                                </button>
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="whatWeDoRight">
                                        <img className="rescue" src={release} />
                                    </div>
                                </div>
                                <div className="whatWeDoBox">
                                    <div className="whatWeDoRight">
                                        <img className="rescue" src={shelter} />
                                    </div>
                                    <div className="whatWeDoLeft">

                                        <p className="subHead recovery">Shelter</p>
                                        <p className="subText recovery">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                        <div className="home-sub-button-box">
                                            <Link className="hbd" to="/resident-animals" >
                                                <button className="donate-button">
                                                    Adopt Virtually
                                                </button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                                <div className="whatWeDoBox">
                                    <div className="whatWeDoLeft">

                                        <p className="subHead">Adoption</p>
                                        <p className="subText">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </p>
                                        <div className="home-sub-button-box release-div">
                                            <Link className="hbd" to='/adopt'>
                                                <button className="donate-button ">
                                                    Give them a happy home
                                                </button>
                                            </Link>
                                        </div>

                                    </div>
                                    <div className="whatWeDoRight">
                                        <img className="rescue" src={adopt} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="healed-hearts-main-box">
                            <center>
                                <p className="heading">Our resident animals</p>
                            </center>
                            <div className="healed-hearts-box">



                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Dexter
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={shelter} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                        <button onClick={() => { openSponsor(1, "Dexter", "VA") }} className="hh-watch">
                                            Adopt Virtually
                                        </button>
                                    </div>
                                </div>

                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Leo
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={shelter} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                        <button onClick={() => { openSponsor(2, "Leo", "VA") }} className="hh-watch">
                                            Adopt Virtually
                                        </button>
                                    </div>
                                </div>

                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Nayla
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={shelter} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                        </p>
                                        <button onClick={() => { openSponsor(3, "Nayla", "VA") }} className="hh-watch">
                                            Adopt Virtually
                                        </button>
                                    </div>
                                </div>





                            </div>

                            <center>
                                <Link className="hbd" to='/resident-animals'>
                                    <button className="read-more-stories">
                                        See all residents
                                    </button>
                                </Link>

                            </center>
                        </div> */}

                        <div className="healed-hearts-main-box">
                            <center>
                                <p className="heading">Sponsor or Adopt</p>
                            </center>
                            <div className="healed-hearts-box">


                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Safari
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={amb} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s sdkfjhdksj dsfkjsfksdf
                                        </p>
                                        <div className="adopt-new-buttons">
                                            {/* <div className="adopt-butns-div">
                                                            <button className="adopt-new-button adn">
                                                                Adopt
                                                            </button>
                                                            <button className="adopt-new-button adn">
                                                                Story
                                                            </button>
                                                        </div> */}
                                            <button onClick={() => { openAdopt(1, "Jackie") }} className="adopt-new-button adn">
                                                Adopt
                                            </button>
                                            <button onClick={() => { openSponsor(1, "Jackie", "S") }} className="adopt-new-button spn">
                                                Sponsor
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Almond
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={amb} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s sdkfjhdksj dsfkjsfksdf

                                        </p>
                                        <div className="adopt-new-buttons">
                                            {/* <div className="adopt-butns-div">
                                                            <button className="adopt-new-button adn">
                                                                Adopt
                                                            </button>
                                                            <button className="adopt-new-button adn">
                                                                Story
                                                            </button>
                                                        </div> */}
                                            <button onClick={() => { openAdopt(3, "Almond") }} className="adopt-new-button adn">
                                                Adopt
                                            </button>
                                            <button onClick={() => { openSponsor(3, "Almond", "S") }} className="adopt-new-button spn">
                                                Sponsor
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="adopt-new">
                                    <p className="adopt-new-name">
                                        Jackie
                                    </p>
                                    <div className="adopt-new-lower-box">
                                        <img className="adopt-new-img" src={amb} />
                                        <p className="adopt-new-dets">
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s sdkfjhdksj dsfkjsfksdf
                                        </p>
                                        <div className="adopt-new-buttons">
                                            {/* <div className="adopt-butns-div">
                                                            <button className="adopt-new-button adn">
                                                                Adopt
                                                            </button>
                                                            <button className="adopt-new-button adn">
                                                                Story
                                                            </button>
                                                        </div> */}
                                            <button onClick={() => { openAdopt(2, "Jackie") }} className="adopt-new-button adn">
                                                Adopt
                                            </button>
                                            <button onClick={() => { openSponsor(2, "Jackie", "S") }} className="adopt-new-button spn">
                                                Sponsor
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <center>
                                <Link className="hbd" to='/adopt'>
                                    <button className="read-more-stories">
                                        See all adoptables
                                    </button>
                                </Link>

                            </center>
                        </div>

                        <div className="stats-main-box">
                            <center>
                                <p className="heading">Our achievements</p>
                            </center>

                            <div className="stats-box">
                                <div className="statOne">
                                    <p className="statText">5000+ animals rescued</p>
                                </div>
                                <div className="statTwo">
                                    <p className="statText">500+ animals live in our shelter</p>
                                </div>
                                <div className="statThree">
                                    <p className="statText">100+ is our staff</p>
                                </div>
                            </div>
                        </div>


                    </div>


                    


                </div>

            </div>
        </>
    )
}

export default Home