import "../../global.scss"
import Iframe from "react-iframe"
import share from "./share.png"
import { useState } from "react";
import qr from "./qr.png"
import Nav from "../nav/nav";
import rightImg from "./about.JPG"
import { Link } from "react-router-dom";



function About() {


    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        amount: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_live_oGKXj8EVJEdpCQ', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {
                    alert('Payment successful!');
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };


    return (
        <div className="Donate">
            <div className="main-box about-box">
                <Nav />
                <div className="main-box-sub about-sub">
                    <div className="left-main-box">
                        {/* <p className="heading wt">
                            About Us
                        </p> */}
                        <p className="tagline">
                            Rescue Recover Release Repeat
                        </p>
                        <p className="left-main-box-text">
                            Empowering lives, one rescue at a time, as we recover hope and release potential, endlessly repeating our commitment to compassion.
                        </p>


                    </div>
                    <div className="right-main-box right-adopt">
                        <img className="right-img" src={rightImg} />
                    </div>

                </div>

            </div>


            <div className="about-content">
                <div className="about-div">
                    <p className="heading">
                        Meet the founder
                    </p>

                    <p className="content-about">
                        Naman Sharma, a compassionate soul at the tender age of 22, embodies the epitome of selflessness and dedication. Since his school days, he embarked on a noble mission to rescue animals in need, driven by an unwavering passion for their welfare. Over the past eight years, his journey has been nothing short of remarkable as he tirelessly devotes himself to the cause. Today, Naman stands proudly at the helm of an animal rescue shelter, where more than 200 creatures find solace and care under his nurturing gaze. Yet, his vision extends far beyond mere sheltering. With an indomitable spirit, Naman dreams of establishing a state-of-the-art animal hospital, a sanctuary where every injured and distressed soul can find healing and comfort. His determination knows no bounds, and with each passing day, he edges closer to turning his dream into a tangible reality, one paw at a time. Naman Sharma isn't just rescuing animals; he's rewriting their stories with hope and compassion, setting a beacon of inspiration for all those who believe in the power of kindness.

                    </p>
                </div>
                <div className="about-div">
                    <p className="heading">
                        Our Mission
                    </p>

                    <p className="content-about">
                        Welcome to our haven of compassion, where every wag of a tail, chirp of a bird, or gentle nuzzle of a snout is cherished and celebrated. At our animal rescue team and shelter, we are not merely caretakers; we are guardians of hope and healing for the most vulnerable members of our world. Founded by Naman Sharma, a beacon of empathy at just 22 years old, our shelter serves as a sanctuary for small animals in distress, including dogs, cats, birds, and monkeys. While our hearts overflow with love for these creatures, we dream of expanding our reach to embrace all beings, great and small.
                        <br /><br />
                        Our mission is rooted in the belief that every animal deserves a chance at a life filled with dignity and comfort. We envision a future where no creature is left behind, where even the largest of souls, like cows and horses, find refuge within our sanctuary walls. But we cannot achieve this dream alone. It is through the unwavering support of donors and supporters like you that we can turn this vision into reality.
                        <br /><br />
                        Naman Sharma's dedication extends beyond the walls of our shelter. He is on a mission to ignite awareness about animal cruelty and to impart essential treatment education to all animal lovers and volunteers. Together, we strive not only to mend broken bodies but also to mend broken spirits and to cultivate a world where kindness and empathy reign supreme.
                        <br /><br />
                        Join us in our journey to create a heaven-like hospital and sanctuary for all animals. Your support is not just a donation; it is a lifeline for those who cannot speak for themselves. Together, we can make a difference—one paw, wing, or hoof at a time. Welcome to our family, where compassion knows no bounds and love knows no limits.

                    </p>
                </div>
                <div className="about-div">
                    <p className="heading">
                        Our Recovery Home
                    </p>

                    <p className="content-about">
                        Welcome to our humble abode of healing and hope nestled in the heart of Khera Khurd village near Rohini Sector 28 in Delhi, India. Here, amidst the bustle of city life, lies a sanctuary for our voiceless friends who have endured the trials of injury and distress. Our recovery home serves as a beacon of light for animals in their darkest hours, offering a safe haven where wounds are mended and spirits are lifted.
                        <br /><br />
                        Within these sheltering walls, small animals—dogs, cats, birds, monkeys, and even small baby calves—find refuge and respite from their suffering. From the moment they enter our care, our dedicated team works tirelessly, around the clock, to provide the love, attention, and medical care needed to guide them from brokenness to wholeness.
                        <br /><br />
                        But our mission cannot thrive without the support of compassionate souls like you. As we strive to save and heal every precious life that comes our way, we rely on the generosity of donors and supporters to sustain and expand our noble work. The rental land on which our shelter stands is a testament to the power of community coming together for a common cause—a cause that transcends boundaries and speaks to the universal language of empathy and compassion.
                        <br /><br />
                        Your support is not just a donation; it is a lifeline for those who cannot advocate for themselves. It is the promise of a brighter future for every animal who graces our doorstep. Join us in our journey to turn brokenness into beauty, one animal at a time. Together, we can make a difference that echoes far beyond the confines of our shelter walls. Together, we can build a world where every creature is treated with the kindness, dignity, and respect they so rightfully deserve. Thank you for being a part of our mission.

                    </p>
                </div>
                <div className="about-div">
                    <p className="heading">
                        Wanna Support us ?
                    </p>

                    <p className="content-about">
                        Your support has the power to transform lives. With just a click, you can become a lifeline for the voiceless creatures in our care. Every donation, no matter how big or small, fuels our mission to rescue, heal, and protect animals in need. Together, we can make a difference. Join us in our journey to create a world where every animal is cherished and valued. Donate now and be a hero to those who need it most. Your generosity matters.


                    </p>
                    <Link to='/donate'>
                        <div className="wishlist-link-box db">
                            <p className="wishlist-text">
                                Donate Us
                            </p>
                            <img className="redirect" src={share} />
                        </div>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default About;