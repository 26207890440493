import "../../global.scss"
import Nav from "../nav/nav";
import firebase from 'firebase/compat/app';
import adopt from "./shelter.jpg"
import animals from "../../animals.json"
import { useEffect, useRef, useState } from "react";
import success from "./success.png"
import warning from "./warning.png"
import da from "./da.png"
import cross from "./close.png"
import { firestore } from "../../firebase";

function Residents() {


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        amount: 0
    });
    const [currentAnimal, setCurrentAnimal] = useState(0)

    const [currentAnimalName, setCurrentAnimalName] = useState(0)

    const [currentAnimalSponsor, setCurrentAnimalSponsor] = useState(0)

    const [currentAnimalNameSponsor, setCurrentAnimalNameSponsor] = useState(0)

    const [payAmount, setPayAmount] = useState(0)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_test_nUKVQmPiWBQbzE', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {
                    const paymentId = response.razorpay_payment_id;
                    document.getElementById("adoptFormSponsor").style.display = "none"
                    document.getElementById("thanksBoxSponsor").style.display = "flex"
                    document.getElementById("fillTextSponsor").style.display = "none"

                    const docId = firestore.collection("Virtual Adoptions").doc().id
                    firestore.collection("Virtual Adoptions").doc(docId).set({
                        id: docId,
                        animalId: currentAnimal,
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        timePeriod: catValue,
                        animalName: currentAnimalName,
                        amount: payAmount,
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        paymentId: paymentId
                    }, { merge: true })
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };







    const openSponsor = (aid, aname) => {
        setCatValue("Select period")
        setCurrentAnimal(aid)
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        document.getElementById("errorBoxSponsor").style.display = "none"
        setCurrentAnimalName(aname)
        document.getElementById("overlaySponsorDiv").style.display = "flex"
        document.getElementById("adoptFormSponsor").style.display = "flex"
        setPayAmount(0)
    }
    const [catValue, setCatValue] = useState("Select period")
    const closeSponsor = () => {
        setCatValue("Select period")
        document.getElementById("adoptFormSponsor").style.display = "flex"
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("errorBoxSponsor").style.display = "none"
        document.getElementById("overlaySponsorDiv").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimalSponsor(0)
        setCurrentAnimalNameSponsor("")
        setPayAmount(0)
    }





    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const openCats = () => {
        if (isOpen) {
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);




    return (

        <>
            <div id="overlaySponsorDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillTextSponsor" className="head-div">
                        <p>Fill the form to adopt {currentAnimalName} virtually :-</p>
                        <img className="cross" onClick={closeSponsor} src={cross} />

                    </div>
                    <form id="adoptFormSponsor" className="payment-sec" onSubmit={(e) => {
                        handlePayment(e)
                    }}  >
                        <div class="dropdown" ref={dropdownRef}>
                            <div onClick={openCats} className="drpbox">
                                <p class="dropbtn">{catValue}</p>
                                <img className={isOpen ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpen && ( 
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setCatValue("1 month"); setIsOpen(false); setPayAmount(1); setFormData({ ...formData, amount: 1 }); }}>1 month</p>
                                    <p href="#" onClick={() => { setCatValue("3 months"); setIsOpen(false); setPayAmount(300); setFormData({ ...formData, amount: 300 }); }}>3 months</p>
                                    <p href="#" onClick={() => { setCatValue("6 months"); setIsOpen(false); setPayAmount(600); setFormData({ ...formData, amount: 600 }); }}>6 months</p>
                                    <p href="#" onClick={() => { setCatValue("12 months"); setIsOpen(false); setPayAmount(1200); setFormData({ ...formData, amount: 1200 }); }}>12 months</p>
                                </div>
                            )}
                        </div>
                        <input className="pay-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="pay-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="pay-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />

                        <input type="submit" className={catValue == "Select period" ? "pay-button disabled" : "pay-button"} value={"Pay Rs." + payAmount} />
                    </form>
                    <div id="thanksBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Thanks you for adopting {currentAnimalName} for {catValue}
                        </p>
                        <p className="teamText">
                            We have sent you the receipt via email
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>

                    <div id="errorBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={warning} />
                        <p className="thanksText errorText">
                            Payment failed
                        </p>
                        <p className="teamText errorText">
                            Please try again
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>
            <div className="Adopt">

                <div className="adopt-head resident-head">
                    <Nav />
                    <div className="adopt-head-sub">
                        <div className="adopt-head-box">
                            <p className="heading wt">
                                Our Resident Animals
                            </p>
                            <p className="tagline">
                                Our lovely homies
                            </p>
                            <p className="sub-tag">
                                Welcome to our shelter! Here, you'll find a delightful array of furry friends who call our shelter home. While you may not be able to take them home physically, you can make a significant difference in their lives through virtual adoption.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="adopt-lower">
                    <div className="adopt-lower-head">
                        <div className="importance-donation">
                            <p className="keyd">
                                By virtually adopting one of our shelter residents, you provide crucial support that directly contributes to their well-being. Your generous donation will go towards covering the cost of their food, medical care, shelter, and other essential needs during the period of your adoption.
                            </p>
                        </div>
                        <div className="importance-donation">
                            <p className="imp-head">
                                How it works:
                            </p>
                            <ol className="key-donate" >
                                <li className="keyd">Browse through our adorable shelter residents below.</li>
                                <li className="keyd">Choose the animal you'd like to virtually adopt.</li>
                                <li className="keyd">Select the duration of your virtual adoption period.</li>
                                <li className="keyd">Make a donation equivalent to the cost of their care for the chosen duration. You can donate money or essential supplies such as medicines, food, and toys.</li>
                            </ol>
                        </div>
                        <div className="importance-donation">
                            <p className="donText">
                                Your virtual adoption not only helps provide for the animal you choose but also supports our ongoing efforts to care for all our shelter residents. Thank you for considering virtual adoption and making a positive impact on the lives of these precious animals.
                            </p>
                        </div>
                        <div className="importance-donation">
                            <p className="donText">
                                <span className="note">Note:</span> Virtual adoption does not grant ownership or physical possession of the animal. It is a symbolic gesture of support for their care during the adoption period.
                            </p>
                        </div>
                    </div>


                    <div className="adoptions">
                        {/* <div className="adopt">
                        <div className="adopt-box-head">
                            <img className="adopt-img" src={adopt} />
                        </div>
                        <div className="adopt-box-lower">
                            <p className="adopt-head-name">Name</p>
                            <div className="adopt-dets">
                                <p className="adopt-age">
                                    2 yrs
                                </p>
                                <p className="adopt-gen">
                                    Male
                                </p>
                            </div>
                            <div className="adopt-buttons ">
                                <button className="adopt-button sp">
                                    Sponsor
                                </button>
                                <button className="adopt-button ad">
                                    Adopt
                                </button>
                            </div>
                        </div>
                    </div> */}




                        {

                            animals.map((a) => (
                                a.resident == true
                                    ? (
                                        <div className="adopt-new">
                                            <p className="adopt-new-name">
                                                {a.name}
                                            </p>
                                            <div className="adopt-new-lower-box">
                                                <img className="adopt-new-img" src={require(`${a.pic}`)} />
                                                <p className="adopt-new-dets">
                                                    {a.about}
                                                </p>
                                                <div className="adopt-new-buttons">


                                                    <button onClick={() => { openSponsor(a.id, a.name) }} className="adopt-new-button spn">
                                                        Adopt Virtually
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    : null
                            ))
                        }









                    </div>
                </div>

            </div>
        </>

    )
}

export default Residents;