import Nav from "../nav/nav";

function Refund() {

    return (
        <>

            <div className="Adopt">

                <div className="adopt-head privacy-head">
                    <Nav />
                    <div className="adopt-head-sub">
                        <div className="adopt-head-box">
                            <p className="heading wt">
                                Refund Policy
                            </p>


                        </div>
                    </div>
                </div>
                <div className="adopt-lower">
                    <div className="content-box">
                        <div >
                            <p className="home-content bt" > REFUNDS (IF APPLICABLE)</p>
                            <p className="home-content" >
                                If you accidently donated the amount more than you wanted to donate then please write us at aashiyanabynaman@gmail.com within 1 day of the transaction,the donated amount will be transferred to the source withing 3-4 working days.
                            </p>
                        </div>

                        <div>
                            <p className="home-content bt" > CONTACT INFORMATION </p>
                            <p className="home-content" >
                                Questions about the Terms of Service should be sent to us at aashiyanabynaman@gmail.com.
                            </p>
                        </div>
                    </div>





                </div>

            </div>
        </>
    )



}

export default Refund;














