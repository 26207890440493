import "../../global.scss"
import Nav from "../nav/nav";
import adopt from "./shelter.jpg"
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";
import { useState } from "react";
import email from "./email.png"
import phone from "./pc.png"
import ig from "./ig.png"

function Contact() {


    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        formState: { errors, isSubmitSuccessful, isSubmitting },
    } = useForm({
        mode: "onTouched",
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState(false);

    // Please update the Access Key in the .env
    const apiKey = process.env.PUBLIC_ACCESS_KEY || "40093d8f-d825-4a63-94b3-1644ea601539";

    const { submit: onSubmit } = useWeb3Forms({
        access_key: apiKey,
        settings: {
            from_name: "Contact Form",
            subject: "Aashiyana Contact Form",
        },
        onSuccess: (msg, data) => {
            setIsSuccess(true);
            setMessage(msg);
            reset();
        },
        onError: (msg, data) => {
            setIsSuccess(false);
            setMessage(msg);
        },
    });



    return (
        <div className="Adopt">

            <div className="adopt-head contact-head">
                <Nav />
                <div className="adopt-head-sub">
                    <div className="adopt-head-box">
                        <p className="heading wt">
                            Contact Us
                        </p>
                        <p className="tagline">
                            Let's get connected
                        </p>
                        <p className="sub-tag">
                            Thank you for reaching out to us. Your support means the world to us! Whether you have a question, suggestion, or simply want to say hello, we're here to listen. Your message will be received by our dedicated team.
                        </p>
                    </div>
                </div>
            </div>
            <div className="adopt-lower">
                <div className="adopt-lower-head">
                    <div className="importance-donation">
                        <p className="key">
                            Your interest in our organization means a great deal to us, as it reflects your commitment to making a positive impact in our community. Whether you have a question, suggestion, or wish to collaborate with us, your communication is invaluable. We are dedicated to fostering meaningful connections and partnerships that further our mission of giving animals a happy life. Rest assured, your message will be received with gratitude and attended to promptly by our team. There are various ways to contact us!!
                        </p>
                    </div>

                </div>


                <div className="donation-methods">
                    <div className="donation-method contact-box">

                        <div className="donation-method-lower contactB">
                            <p className="dm-det">
                                Fill out the form below and let's make a difference together!
                            </p>


                            <>
                                <form onSubmit={handleSubmit(onSubmit)} className="payment-sec">
                                    <input
                                        type="checkbox"
                                        id=""
                                        className="hidden"
                                        style={{ display: "none" }}
                                        {...register("botcheck")}></input>


                                    <input
                                        type="text"
                                        placeholder="Full Name"
                                        autoComplete="false"
                                        className="pay-inp"
                                        {...register("name", {
                                            required: "Full name is required",
                                            maxLength: 80,
                                        })}
                                        required
                                    />





                                    <input
                                        id="email_address"
                                        type="email"
                                        placeholder="Email Address"
                                        name="email"
                                        autoComplete="false"
                                        className="pay-inp"
                                        {...register("email", {
                                            required: "Enter your email",
                                            pattern: {
                                                value: /^\S+@\S+$/i,
                                                message: "Please enter a valid email",
                                            },
                                        })}
                                        required
                                    />




                                    <textarea
                                        name="message"
                                        placeholder="Your Message"
                                        className="pay-inp"
                                        {...register("message", {
                                            required: "Enter your Message",
                                        })}
                                        required
                                    />



                                    <input
                                        type="submit"
                                        className="pay-button"
                                        value="Send Message"

                                    />
                                </form>

                                {isSubmitSuccessful && isSuccess && (
                                    <div className="mt-3 text-sm text-center text-green-500">
                                        {message || "Success. Message sent successfully"}
                                    </div>
                                )}
                                {isSubmitSuccessful && !isSuccess && (
                                    <div className="mt-3 text-sm text-center text-red-500">
                                        {message || "Something went wrong. Please try later."}
                                    </div>
                                )}
                            </>



                        </div>
                        <p>OR</p>
                        <div className="social-media-boxa">
                            {/* <img className="smi" src={email} />
                            <img className="smi" src={phone} />
                            <img className="smi" src={ig} /> */}
                            <p>Phone No. : +919873995395</p>
                          

                            <p>Email Id : aashiyanabynaman@gmail.com</p>
                            <p>Address : XIV/10513 , BAGH RAO JI , ARYA NAGAR , BASTI NARIAL WALI , MANAKPURA , KAROL BAGH , NEW DELHI </p>
                        </div>
                    </div>











                </div>


            </div>

        </div>
    )
}

export default Contact;