import { Link } from "react-router-dom"
import "../../global.scss"
import menu from "./menu.png"
import aswt from "./arht1.png"
import cross from "./close.png"
import ast from "./arhslt2.png"
import { useEffect, useState } from "react"
import {  useLocation } from "react-router-dom";

function Nav() {

    const location = useLocation();

    const isActive = (path) => location.pathname === path;


    const closeNav = () => {
        document.getElementById("overlayNav").style.width = "0cm";
    }

    const openNav = () => {
        document.getElementById("overlayNav").style.width = "100%";
    }

    const [logoImg, setlogoImg] = useState(aswt);

    useEffect(() => {
        if (window.innerWidth < 500) {
            setlogoImg(ast)
        }
        else {
            setlogoImg(aswt)
        }
    }, [window.innerWidth])


    return (
        <>
            <div id="overlayNav" className="overlayNav">
                <div className="overlay-nav-box">
                    <div className="close-box">
                        <img className="close" onClick={closeNav} src={cross} />
                    </div>
                    <div className="overlay-menu">

                        <Link to='/about'>
                            <p className="overlay-menu-item" onClick={closeNav} > About </p>
                        </Link>
                        <Link to='/adopt'>
                            <p className="overlay-menu-item" onClick={closeNav} > Adopt </p>
                        </Link>
                        <Link to='/donate'>
                            <p className="overlay-menu-item" onClick={closeNav} > Donate </p>
                        </Link>
                        <Link to='/resident-animals'>
                            <p className="overlay-menu-item" onClick={closeNav} > Resident Animals </p>
                        </Link>
                        <Link to='/contact'>
                            <p className="overlay-menu-item" onClick={closeNav} > Contact </p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="Nav">
                <div className="nav-box">
                    <Link to="/">
                        <div className="logo-div">
                            <img className="logoImg" src={logoImg} />
                        </div>
                    </Link>
                    <div className="menu">
                        <Link to='/about'>
                            <p className="menu-item" > About </p>
                        </Link>
                        <Link to='/adopt'>
                            <p className="menu-item" > Adopt </p>
                        </Link>
                        <Link to='/donate'>
                            <p className="menu-item" > Donate </p>
                        </Link>
                        <Link to='/resident-animals'>
                            <p className="menu-item" > Resident Animals </p>
                        </Link>
                        <Link to='/contact'>
                            <p className="menu-item" > Contact </p>
                        </Link>
                        <img className="menuImg" onClick={openNav} src={menu} />


                    </div>
                </div>
            </div>
        </>
    )
}

export default Nav