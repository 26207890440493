import logo from './logo.svg';
import './global.scss';
import Home from './components/home/home';
import { Route, Routes } from 'react-router-dom';
import Adoption from './components/adoption/adopt';
import Donate from './components/donate/donate';
import Residents from './components/residents/residents';
import Contact from './components/contact/contact';
import About from './components/about/about';
import up from "./up.png"
import { useEffect, useState } from 'react';
import "./Stella.ttf"
import Footer from './components/footer/footer';
import PrivacyT from './components/privacy/privacy';
import Refund from './components/refund/refund';
import Terms from './components/terms/terms';
import Nav from './components/nav/nav';

function App() {

  const [showScroll, setShowScroll] = useState(false)


  useEffect(() => {
    const handleScroll = () => {
      // Your scroll-related logic or function call here
      if (window.scrollY > 10) {
        document.getElementById("scrollUp").style.display = "flex"
      }
      else if (window.scrollY < 10) {
        document.getElementById("scrollUp").style.display = "none"
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const clickScroll = (e) => {
    e.preventDefault()


    window.scrollTo(0, 0);
  }


  return (
    <>

      <div className='review-div'>
        <div className='review-box'>
            
        </div>
      </div>
      <div className="App">

        <div id='scrollUp' onClick={clickScroll} className='scroll-up'>
          <img className='upA' src={up} />
        </div>
        {/* <Nav /> */}

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/adopt' element={<Adoption />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/resident-animals' element={<Residents />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/privacy-policy' element={<PrivacyT />} />
          <Route path='/refund-policy' element={<Refund />} />
          <Route path='/terms' element={<Terms />} />
        </Routes>

        <Footer />
      </div>
    </>
  );
}

export default App;
